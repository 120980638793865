<template>
  <div class="wrapper">
    <van-nav-bar left-arrow left-text="返回" @click-left="onClickLeft">
      <template #title>
        <div class="position">
          <van-icon name="location-o" />
          <span v-if="$store.state.LocationMessage">
            {{ $store.state.LocationMessage.province }}
            {{ $store.state.LocationMessage.city }}
          </span>
          <span v-else> 请求定位中 </span>
        </div>
      </template>
    </van-nav-bar>
    <van-list>
      <div
        class="card van-hairline--surround"
        v-for="(item, index) in list"
        :key="index"
      >
        <van-image
          :src="
            'http://www.weather.com.cn/m2/i/about/alarmpic/' +
            item.levelTypeCode +
            item.levelCode +
            '.gif'
          "
        />
        <h4>{{ item.title }}</h4>
        <span>发布日期:{{ item.date }}</span>
        <p>{{ item.content }}</p>
      </div>
    </van-list>
  </div>
</template>
<script>
import { NavBar, Icon, List, Image } from "vant"
import { getWarning } from "@/service/warning.js"
export default {
  name: "Warning",
  data() {
    return {
      list: []
    }
  },
  components: {
    VanNavBar: NavBar,
    VanIcon: Icon,
    VanList: List,
    VanImage: Image
  },
  created() {
    getWarning(this.$store.state.LocationMessage.lon, this.$store.state.LocationMessage.lat).then((res) => {
      this.list = res.data.warm.w
    })
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='scss' scoped>
$color: #001f90;
.wrapper {
  .position {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    margin: 16px;
  }
}
</style>
